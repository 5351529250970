 
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
 
import tracker from './tracker'
// import trackerAssist from '@openreplay/tracker-assist'
// import trackerAxios from '@openreplay/tracker-axios'

// tracker.use(trackerAxios({}))
// tracker.use(trackerAssist({}))
tracker.start()

ReactDOM.render( 
    <App />
 ,
  document.getElementById('main')
);
