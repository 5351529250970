import React, { Suspense, useState, useEffect } from "react";
import tracker from '../tracker'
// import PropTypes from 'prop-types';
// import { Route, Switch } from 'react-router-dom';
import './Landing.css'
const IframeComponent = React.lazy(() => import("./IframeComponent"));

const LOGO_ELEMENT = <div className='logo-box'> {'< QC />'}</div>
const Landing = () => {

  // return <div> this is test line </div>; 
  const [iframeElem, setIframeElem] = useState('loading')
  // Example POST method implementation:
async function postData(  ) {
  console.log("clicke")
  // 
  let url = 'https://dev-api.querycharts.com/apps/editor/controllers/queries?embedded_query_id='; 

  // url = 'http://localhost:3001/apps/editor/controllers/queries?embedded_query_id='; 
  let input = document.getElementById('input_field').value 
  url+= input

  // let data = {}
    // Default options are marked with *
    let headers ={}
    if (tracker && tracker.getSessionToken()) {
      headers['x-openreplay-sessiontoken'] = tracker.getSessionToken(); // Inject openreplay_session_id
    }
     
    if (tracker && tracker.getSessionURL()) {
      headers['x-openreplay-sessionurl'] = tracker.getSessionURL(); // Inject openreplay_session_id
    }
    // console.log( 'header,',headers)
    // console.log(  tracker.getSessionURL())
    // console.log(  tracker.getSessionID())
    const response = await fetch(url, {
      method: "GET", // *GET, POST, PUT, DELETE, etc.
      // // mode: "no-cors", // no-cors, *cors, same-origin
      // // cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      // credentials: "include", // include, *same-origin, omit
      headers: headers,
      // redirect: "follow", // manual, *follow, error
      // referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      // // body: JSON.stringify(data), // body data type must match "Content-Type" header
    });
//     console.log(  response.json());
// return 
    let resultData = await response.json()
    console.log(resultData);
    return resultData; // parses JSON response into native JavaScript objects
  }
  useEffect(() => {

    document.onscroll = renderIframe;
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function renderIframe() {
    console.log(iframeElem)

    console.log('renderign')
    setIframeElem(<IframeComponent />)
    document.onscroll = null;
  }
  return (
    <div>


      {/* <!--[if lte IE 9]>
      <p className="browserupgrade">
        You are using an <strong>outdated</strong> browser. Please
        <a href="https://browsehappy.com/">upgrade your browser</a> to improve
        your experience and security.
      </p>
    <![endif]--> */}

      {/* <!-- ======== preloader start ======== --> */}
      <div className="preloader">
        <div className="loader">
          <div className="spinner">
            <div className="spinner-container">
              <div className="spinner-rotator">
                <div className="spinner-left">
                  <div className="spinner-circle"></div>
                </div>
                <div className="spinner-right">
                  <div className="spinner-circle"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- preloader end -->

    <!-- ======== header start ======== --> */}
      <header className="header" id="home">
        <div className="navbar-area">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-12">
                <nav className="navbar navbar-expand-lg">
                  <a className="navbar-brand" href="/">
                    <img src="assets/img/logo/logo-4.png" alt="Logo" style={{ display: 'none' }} />
                    {LOGO_ELEMENT}
                  </a>
                  <button
                    className="navbar-toggler"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#navbarSupportedContent"
                    aria-controls="navbarSupportedContent"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                  >
                    <span className="toggler-icon"></span>
                    <span className="toggler-icon"></span>
                    <span className="toggler-icon"></span>
                  </button>

                  <div
                    className="collapse navbar-collapse sub-menu-bar"
                    id="navbarSupportedContent"
                  >
                    <ul id="nav" className="navbar-nav" style={{ flex: '1 0 0' }}>
                      {/* <li className="nav-item">
                        <a className="page-scroll active" href="#home">Home</a>
                      </li> */}
                      <li className="nav-item">
                        <a className="page-scroll" href="#features">Features</a>
                      </li>
                      {/* <li className="nav-item">
                        <a className="page-scroll" href="#about">About</a>
                      </li> */}

                      {/* <li className="nav-item">
                        <a className="page-scroll" href="#why">Why</a>
                      </li> */}
                      <li className="nav-item">
                        <a className="page-scroll" href="#pricing">Pricing</a>
                      </li>
                      <li className="nav-item">
                        <a className="page-scroll" href="#demo">Demo</a>
                      </li>
                      <li className="nav-item" style={{ flex: '1 0 0', textAlign: 'right' }}>
                        <a href="https://app.querycharts.com/auth/login">Login</a>
                      </li>
                      <li className="nav-item signup-button-link" style={{   }}>
                        <a href="https://app.querycharts.com/auth/register">Sign Up</a>
                      </li>
                    </ul>
                
                  </div>
                  {/* <a id="header_signup"
                    href="https://app.querycharts.com/auth/login"
                    className="main-btn border-btn btn-hover wow fadeInUp main-btn"
                    data-wow-delay=".6s"
                  >Login</a> */}
                  {/* <a id="header_signup"
                    href="https://app.querycharts.com/auth/register"
                    className="main-btn border-btn btn-hover wow fadeInUp main-btn"
                    data-wow-delay=".6s"
                  >Sign Up</a> */}
                  {/* <!-- navbar collapse --> */}
                </nav>
                {/* <!-- navbar --> */}
              </div>
            </div>
            {/* <!-- row --> */}
          </div>
          {/* <!-- container --> */}
        </div>
        {/* <!-- navbar area --> */}
      </header>
      {/* <!-- ======== header end ======== -->

    <!-- ======== hero-section start ======== --> */}
      <section id="home" className="hero-section">
        <div className="container">
          <div className="row align-items-center position-relative">
            <div className="col-lg-6">
              <div className="hero-content">
                <h1 className="wow fadeInUp" data-wow-delay=".4s">
                  Build SQL forms and charts without writing code!
                </h1>
                <p className="wow fadeInUp" data-wow-delay=".6s">
                  The fastest way to build and share forms, charts and tables on top of your SQL database!
                  Use our intuitive platform to share/embed query results, conduct surveys and save data directly into your database, all without writing code!
                </p>
                <span className="get-start-button-pr">
                  <a
                    href="https://app.querycharts.com/auth/register"
                    className="main-btn border-btn btn-hover wow fadeInUp"
                    data-wow-delay=".6s"
                  > Try for Free </a    >
                  <span className="no-credit-tl" >No Credit Card Required!</span>
                  <a href="#features" className="scroll-bottom">
                    <i className="lni lni-arrow-down"></i
                    ></a>
                </span>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="hero-img wow fadeInUp" data-wow-delay=".5s">
                <img src="assets/img/main_img-0.png" alt="" className='main-img' draggable="false" />
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- ======== hero-section end ======== -->

    <!-- ======== feature-section start ======== --> */}
      {/* <section id="features" className="feature-section pt-120">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-4 col-md-8 col-sm-10">
              <div className="single-feature">
                <div className="icon">
                  <i className="lni lni-bootstrap"></i>
                </div>
                <div className="content">
                  <h3>Easy to plug in</h3>
                  <p>
                    Now, easily plug in your data from multiple sources, join data, filter and group data and generate visualizations and reports without writing any code.

                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-8 col-sm-10">
              <div className="single-feature">
                <div className="icon">
                  <i className="lni lni-layout"></i>
                </div>
                <div className="content">
                  <h3>Secure</h3>
                  <p>We understand the importance of data security and the trust our users place in us to protect their sensitive information.
                    We have implemented a number of measures to ensure that your information is safe and secure.

                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-8 col-sm-10">
              <div className="single-feature">
                <div className="icon">
                  <i className="lni lni-coffee-cup"></i>
                </div>
                <div className="content">
                  <h3>Seamless</h3>
                  <p>
                    We understand that your time is valuable, and we want to make sure that every interaction you have with our platform is smooth and hassle-free.

                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      {/* <!-- ======== feature-section end ======== -->

    <!-- ======== about-section start ======== --> */}
      <section id="features" className="about-section pt-150">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-xl-6 col-lg-6">
              <div className="about-img">
                <img src="assets/img/about/about-1.png" alt="" className="w-100" />
                <img
                  src="assets/img/about/about-left-shape.svg"
                  alt=""
                  className="shape shape-1"
                />
                <img
                  src="assets/img/about/left-dots.svg"
                  alt=""
                  className="shape shape-2"
                />
              </div>
            </div>
            <div className="col-xl-6 col-lg-6">
              <div className="about-content">
                <div className="section-title mb-30">
                  <h2 className="mb-25 wow fadeInUp" data-wow-delay=".2s">
                    Powerful Query Builder and SQL Browser
                  </h2>
                  <p className="wow fadeInUp" data-wow-delay=".4s">
                    Quickly build queries with nested inserts and joins using our simple yet powerful query builder. Skip the hassle of manually writing your queries and get to the part that matters the most - the SQL results!
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- ======== about-section end ======== -->

    <!-- ======== about2-section start ======== --> */}
      <section id="about" className="about-section pt-150">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-xl-6 col-lg-6">
              <div className="about-content">
                <div className="section-title mb-30">
                  <h2 className="mb-25 wow fadeInUp" data-wow-delay=".2s">
                    Build SQL Forms
                  </h2>
                  <p className="wow fadeInUp" data-wow-delay=".4s">
                    Make forms on top of your SQL database without writing code! Conduct surveys, replace third party form apps and start saving data directly into your database. Insert linked data across connected tables with automatic joins!
                  </p>
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 order-first order-lg-last">
              <div className="about-img-2">
                <img src="assets/img/about/about-2.png" alt="" className="w-100" />
                <img
                  src="assets/img/about/about-right-shape.svg"
                  alt=""
                  className="shape shape-1"
                />
                <img
                  src="assets/img/about/right-dots.svg"
                  alt=""
                  className="shape shape-2"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- ======== about2-section end ======== -->

      <!-- ======== about3-section start ======== --> */}
      <section id="features" className="about-section pt-150">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-xl-6 col-lg-6">
              <div className="about-img">
                <img src="assets/img/about/about-1.png" alt="" className="w-100" />
                <img
                  src="assets/img/about/about-left-shape.svg"
                  alt=""
                  className="shape shape-1"
                />
                <img
                  src="assets/img/about/left-dots.svg"
                  alt=""
                  className="shape shape-2"
                />
              </div>
            </div>
            <div className="col-xl-6 col-lg-6">
              <div className="about-content">
                <div className="section-title mb-30">
                  <h2 className="mb-25 wow fadeInUp" data-wow-delay=".2s">
                    Build SQL Tables and Charts
                  </h2>
                  <p className="wow fadeInUp" data-wow-delay=".4s">
                    Make tables, pie charts, line charts and time series charts without writing code! Our intuitive interface makes it easy to create and share query results in a few clicks. Making and sharing SQL charts has never been this easy!
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- ======== about3-section end ======== -->

    <!-- ======== feature-section start ======== --> */}
      {/* <section id="why" className="feature-extended-section pt-100">
        <div className="feature-extended-wrapper">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-xxl-5 col-xl-6 col-lg-8 col-md-9">
                <div className="section-title text-center mb-60">
                  <h2 className="mb-25 wow fadeInUp" data-wow-delay=".2s">
                    WHY QUERY CHARTS
                  </h2>
                  <p className="wow fadeInUp" data-wow-delay=".4s">
                    Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                    diam nonumy eirmod tempor invidunt ut labore et dolore
                  </p>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-4 col-md-6">
                <div className="single-feature-extended">
                  <div className="icon">
                    <i className="lni lni-display"></i>
                  </div>
                  <div className="content">
                    <h3>Tables</h3>
                    <p>
                      With the help of Tables, manage and analyze your data hassle free. Whether you're working with sales figures, customer data, or any other type of data, tables can help you organize and make sense of your data in a way that is clear, concise, and actionable.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="single-feature-extended">
                  <div className="icon">
                    <i className="lni lni-leaf"></i>
                  </div>
                  <div className="content">
                    <h3>Forms</h3>
                    <p>
                      Now collect and analyze data with the help of a powerful form option and they can make a significant impact on your business. Create custom forms that suit your specific needs, gather valuable insights about your audience, and make data-driven decisions that can help you grow your business.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="single-feature-extended">
                  <div className="icon">
                    <i className="lni lni-grid-alt"></i>
                  </div>
                  <div className="content">
                    <h3>Charts</h3>
                    <p>
                      With charts, it is easy for you to create visualizations that help you analyze and interpret your data. You can identify patterns and trends in your data that might otherwise go unnoticed. 
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="single-feature-extended">
                  <div className="icon">
                    <i className="lni lni-javascript"></i>
                  </div>
                  <div className="content">
                    <h3>Dashboards</h3>
                    <p>
                      With our dashboard option, you can create custom dashboards that display the data that is most important to you. You can easily add and remove widgets, such as charts, graphs, and tables, to create a personalized view of your data.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="single-feature-extended">
                  <div className="icon">
                    <i className="lni lni-layers"></i>
                  </div>
                  <div className="content">
                    <h3>Embed</h3>
                    <p>
                      Now easily embed charts, graphs, tables, and other visualizations directly into your website or blog. Customize the appearance of your embedded content to match the design of your website or blog, that best suits your needs.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="single-feature-extended">
                  <div className="icon">
                    <i className="lni lni-rocket"></i>
                  </div>
                  <div className="content">
                    <h3>Highly Optimized</h3>
                    <p>
                      Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                      diam nonumy eirmod tempor invidunt ut labore
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      {/* <!-- ======== feature-section end ======== -->

    <!-- ======== pricing-section end ======== --> */}
      <section id="pricing" className="pricing-section pt-120 pb-120">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xxl-5 col-xl-6 col-lg-8 col-md-9">
              <div className="section-title text-center mb-35">
                <h2 className="mb-25 wow fadeInUp" data-wow-delay=".2s">
                  {/* Choose a Plan */}
                  Pricing
                </h2>
                <p className="wow fadeInUp" data-wow-delay=".4s">
                  Since, we are in Beta, it is Free to use
                </p>
              </div>
            </div>
          </div>
{/* 
          <div className="pricing-nav-wrapper mb-60">
            <ul className="nav nav-pills" id="pills-tab" role="tablist">
              <li role="presentation">
                <a
                  className="active"
                  id="pills-month-tab"
                  data-bs-toggle="pill"
                  href="#pills-month"
                  role="tab"
                  aria-controls="pills-month"
                  aria-selected="true"
                >Monthly</a
                >
              </li>
              <li role="presentation">
                <a
                  id="pills-year-tab"
                  data-bs-toggle="pill"
                  href="#pills-year"
                  role="tab"
                  aria-controls="pills-year"
                  aria-selected="false"
                >Yearly</a
                >
              </li>
            </ul>
          </div> */}

          <div className="tab-content" id="pills-tabContent">
            <div
              className="tab-pane fade show active"
              id="pills-month"
              role="tabpanel"
              aria-labelledby="pills-month-tab"
            >
              <div className="row justify-content-center">

              <div className="col-lg-4 col-md-8 col-sm-10">
                  <div className="single-pricing">
                    <div className="pricing-header">
                      <h1 className="price">$0</h1>
                      <h3 className="package-name">Free Account</h3>
                    </div>
                    <div className="content">
                      <ul>
                        <li>
                          <i className="lni lni-checkmark active"></i> Unlimited Acces
                        </li>
                   
                        <li>
                          <i className="lni lni-checkmark active"></i> Unlimited
                          Storage
                        </li>
                        <li>
                          <i className="lni lni-checkmark active"></i> 24/7 Support
                        </li>
                        <li>
                          <i className="lni lni-checkmark active"></i> Free Future
                          Updates
                        </li>
                      </ul>
                    </div>
                    <div className="pricing-btn">
                      <a href="https://app.querycharts.com/auth/register" className="main-btn btn-hover"
                      >Get Started</a
                      >
                    </div>
                    {/* <div className="pricing-btn">
                      <a
                        href="/"
                        className="main-btn btn-hover border-btn"
                      >Get Start</a
                      >
                    </div> */}
                  </div>
                </div>


                {/* <div className="col-lg-4 col-md-8 col-sm-10">
                  <div className="single-pricing">
                    <div className="pricing-header">
                      <h1 className="price">$36</h1>
                      <h3 className="package-name">Basic Account</h3>
                    </div>
                    <div className="content">
                      <ul>
                        <li>
                          <i className="lni lni-checkmark active"></i> Unlimited Acces
                        </li>
                        <li>
                          <i className="lni lni-checkmark active"></i> Single User
                        </li>
                        <li>
                          <i className="lni lni-checkmark active"></i> Unlimited
                          Storage
                        </li>
                        <li><i className="lni lni-close"></i> 24/7 Support</li>
                        <li><i className="lni lni-close"></i> Free Future Updates</li>
                      </ul>
                    </div>
                    <div className="pricing-btn">
                      <a
                        href="/"
                        className="main-btn btn-hover border-btn"
                      >Get Start</a
                      >
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-8 col-sm-10">
                  <div className="single-pricing">
                    <div className="pricing-header">
                      <h1 className="price">$56</h1>
                      <h3 className="package-name">Standard Account</h3>
                    </div>
                    <div className="content">
                      <ul>
                        <li>
                          <i className="lni lni-checkmark active"></i> Unlimited Acces
                        </li>
                        <li>
                          <i className="lni lni-checkmark active"></i> 20+ Users
                        </li>
                        <li>
                          <i className="lni lni-checkmark active"></i> Unlimited
                          Storage
                        </li>
                        <li>
                          <i className="lni lni-checkmark active"></i> 24/7 Support
                        </li>
                        <li>
                          <i className="lni lni-checkmark active"></i> Free Future
                          Updates
                        </li>
                      </ul>
                    </div>
                    <div className="pricing-btn">
                      <a href="/" className="main-btn btn-hover"
                      >Get Start</a
                      >
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-8 col-sm-10">
                  <div className="single-pricing">
                    <div className="pricing-header">
                      <h1 className="price">$89</h1>
                      <h3 className="package-name">Premium Account</h3>
                    </div>
                    <div className="content">
                      <ul>
                        <li>
                          <i className="lni lni-checkmark active"></i> Unlimited Acces
                        </li>
                        <li>
                          <i className="lni lni-checkmark active"></i> Unlimited Users
                        </li>
                        <li>
                          <i className="lni lni-checkmark active"></i> Unlimited
                          Storage
                        </li>
                        <li>
                          <i className="lni lni-checkmark active"></i> 24/7 Support
                        </li>
                        <li>
                          <i className="lni lni-checkmark active"></i> Free Future
                          Updates
                        </li>
                      </ul>
                    </div>
                    <div className="pricing-btn">
                      <a
                        href="/"
                        className="main-btn btn-hover border-btn"
                      >Get Start</a
                      >
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
            <div
              className="tab-pane fade"
              id="pills-year"
              role="tabpanel"
              aria-labelledby="pills-year-tab"
            >
              <div className="row justify-content-center">
                <div className="col-lg-4 col-md-8 col-sm-10">
                  <div className="single-pricing">
                    <div className="pricing-header">
                      <h1 className="price">$136</h1>
                      <h3 className="package-name">Basic Account</h3>
                    </div>
                    <div className="content">
                      <ul>
                        <li>
                          <i className="lni lni-checkmark active"></i> Unlimited Acces
                        </li>
                        <li>
                          <i className="lni lni-checkmark active"></i> Single User
                        </li>
                        <li>
                          <i className="lni lni-checkmark active"></i> Unlimited
                          Storage
                        </li>
                        <li><i className="lni lni-close"></i> 24/7 Support</li>
                        <li><i className="lni lni-close"></i> Free Future Updates</li>
                      </ul>
                    </div>
                    <div className="pricing-btn">
                      <a
                        href="/"
                        className="main-btn btn-hover border-btn"
                      >Get Start</a
                      >
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-8 col-sm-10">
                  <div className="single-pricing">
                    <div className="pricing-header">
                      <h1 className="price">$156</h1>
                      <h3 className="package-name">Standard Account</h3>
                    </div>
                    <div className="content">
                      <ul>
                        <li>
                          <i className="lni lni-checkmark active"></i> Unlimited Acces
                        </li>
                        <li>
                          <i className="lni lni-checkmark active"></i> 20+ Users
                        </li>
                        <li>
                          <i className="lni lni-checkmark active"></i> Unlimited
                          Storage
                        </li>
                        <li>
                          <i className="lni lni-checkmark active"></i> 24/7 Support
                        </li>
                        <li><i className="lni lni-close"></i> Free Future Updates</li>
                      </ul>
                    </div>
                    <div className="pricing-btn">
                      <a href="/" className="main-btn btn-hover"
                      >Get Start</a
                      >
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-8 col-sm-10">
                  <div className="single-pricing">
                    <div className="pricing-header">
                      <h1 className="price">$189</h1>
                      <h3 className="package-name">Premium Account</h3>
                    </div>
                    <div className="content">
                      <ul>
                        <li>
                          <i className="lni lni-checkmark active"></i> Unlimited Acces
                        </li>
                        <li>
                          <i className="lni lni-checkmark active"></i> Unlimited Users
                        </li>
                        <li>
                          <i className="lni lni-checkmark active"></i> Unlimited
                          Storage
                        </li>
                        <li>
                          <i className="lni lni-checkmark active"></i> 24/7 Support
                        </li>
                        <li>
                          <i className="lni lni-checkmark active"></i> Free Future
                          Updates
                        </li>
                      </ul>
                    </div>
                    <div className="pricing-btn">
                      <a
                        href="/"
                        className="main-btn btn-hover border-btn"
                      >Get Start</a
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- ======== pricing-section end ======== -->

    <!-- ======== testimonial-section start ======== --> */}
      {/* {testimonial} */}
      {testimonial}
      {/* <!-- ======== testimonial-section end ======== -->

     
    <!-- ======== subscribe-section start ======== --> */}

      <Suspense fallback={<div>Loading</div>}>
        {iframeElem}
      </Suspense>

      {/* <section id="contact" className="subscribe-section pt-120">
        <div className="container">
          <div className="subscribe-wrapper img-bg">
            <div className="row align-items-center">
              <div className="col-xl-6 col-lg-7">
                <div className="section-title mb-15">
                  <h2 className="text-white mb-25">Subscribe Our Newsletter</h2>
                  <p className="text-white pr-5">
                    Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                    diam nonumy eirmod tempor
                  </p>
                </div>
              </div>
              <div className="col-xl-6 col-lg-5">
                <form action="#" className="subscribe-form">
                  <input
                    type="email"
                    name="subs-email"
                    id="subs-email"
                    placeholder="Your Email"
                  />
                  <button type="submit" className="main-btn btn-hover">
                    Subscribe
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      {/* <!-- ======== subscribe-section end ======== -->

    <!-- ======== footer start ======== --> */}


      {/* <footer className="footer">
        <div className="container">
          <div className="widget-wrapper">
            <div className="row">
              <div className="col-xl-4 col-lg-4 col-md-6">
                <div className="footer-widget">
                  <div className="logo mb-30">
                    <a href="index.html">
                      // {/* <img src="/assets/img/logo/logo.svg" alt="" />  
                     {LOGO_ELEMENT}  
                  </div>
                  <p className="desc mb-30 text-white">
                    Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                    dinonumy eirmod tempor invidunt.
                  </p>
                  <ul className="socials">
                    <li>
                      <a href="jvascript:void(0)">
                        <i className="lni lni-facebook-filled"></i>
                      </a>
                    </li>
                    <li>
                      <a href="jvascript:void(0)">
                        <i className="lni lni-twitter-filled"></i>
                      </a>
                    </li>
                    <li>
                      <a href="jvascript:void(0)">
                        <i className="lni lni-instagram-filled"></i>
                      </a>
                    </li>
                    <li>
                      <a href="jvascript:void(0)">
                        <i className="lni lni-linkedin-original"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="col-xl-2 col-lg-2 col-md-6">
                <div className="footer-widget">
                  <h3>About Us</h3>
                  <ul className="links">
                    <li><a href="/">Home</a></li>
                    <li><a href="/">Feature</a></li>
                    <li><a href="/">About</a></li>
                    <li><a href="/">Testimonials</a></li>
                  </ul>
                </div>
              </div>

              <div className="col-xl-3 col-lg-3 col-md-6">
                <div className="footer-widget">
                  <h3>Features</h3>
                  <ul className="links">
                    <li><a href="/">How it works</a></li>
                    <li><a href="/">Privacy policy</a></li>
                    <li><a href="/">Terms of service</a></li>
                    <li><a href="/">Refund policy</a></li>
                  </ul>
                </div>
              </div>

              <div className="col-xl-3 col-lg-3 col-md-6">
                <div className="footer-widget">
                  <h3>Other Products</h3>
                  <ul className="links">
                    <li><a href="jvascript:void(0)">Accounting Software</a></li>
                    <li><a href="jvascript:void(0)">Billing Software</a></li>
                    <li><a href="jvascript:void(0)">Booking System</a></li>
                    <li><a href="jvascript:void(0)">Tracking System</a></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer> */}
      {/* <!-- ======== footer end ======== -->

    <!-- ======== scroll-top ======== --> */}
      <a href="/#home" className="scroll-top btn-hover">
        <i className="lni lni-chevron-up"></i>
      </a>

    {
      window.location.search === '?type=test' ? 
      <>
      <button onClick={postData} >click me</button>
      <input id='input_field' type="text" defaultValue={'f1fc6f8d-6d3f-4bdf-add5-08c98c9d17ee'} />
      </> : null
    }
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
    </div>
  );

};


export default Landing;
 

// eslint-disable-next-line no-unused-vars
let testimonial = <>    <section id="testimonials" className="testimonial-section" style={{ display: 'none' }}>
  <div className="container">
    <div className="section-title text-center">
      <h2 className="mb-30">What our customers says</h2>
    </div>
    <div className="testimonial-active-wrapper">
      <div className="shapes">
        <img
          src="assets/img/testimonial/testimonial-shape.svg"
          alt=""
          className="shape shape-1"
        />
        <img
          src="assets/img/testimonial/testimonial-dots.svg"
          alt=""
          className="shape shape-2"
        />
      </div>

      <div className="testimonial-active">
        {/* <!-- single testimonial --> */}
        <div className="single-testimonial">
          <div className="row">
            <div className="col-xl-5 col-lg-5">
              <div className="testimonial-img">
                <img
                  src="assets/img/testimonial/testimonial-1.png"
                  alt=""
                />
                <div className="quote">
                  <i className="lni lni-quotation"></i>
                </div>
              </div>
            </div>

            <div className="col-xl-6 offset-xl-1 col-lg-6 offset-lg-1">
              <div className="content-wrapper">
                <div className="content">
                  <p>
                    Lorem ipsum dolor sit amet, consetetur sadipscing elitr,
                    sed dinonumy eirmod tempor invidunt ut labore et dolore
                    magna aliquyam erat, sed diam voluptua. At vero eos et
                    accusam et justo duo dolores et ea rebum. Stet clita
                    kasd gubergren, no sea takimata sanctus est Lorem.
                  </p>
                </div>
                <div className="info">
                  <h4>Jonathon Smith</h4>
                  <p>Developer and Youtuber</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <!-- single testimonial --> */}
        <div className="single-testimonial">
          <div className="row">
            <div className="col-xl-5">
              <div className="testimonial-img">
                <img
                  src="assets/img/testimonial/testimonial-2.png"
                  alt=""
                />
                <div className="quote">
                  <i className="lni lni-quotation"></i>
                </div>
              </div>
            </div>

            <div className="col-xl-6 offset-xl-1">
              <div className="content-wrapper">
                <div className="content">
                  <p>
                    Lorem ipsum dolor sit amet, consetetur sadipscing elitr,
                    sed dinonumy eirmod tempor invidunt ut labore et dolore
                    magna aliquyam erat, sed diam voluptua. At vero eos et
                    accusam et justo duo dolores et ea rebum. Stet clita
                    kasd gubergren, no sea takimata sanctus est Lorem.
                  </p>
                </div>
                <div className="info">
                  <h4>Gray Simon</h4>
                  <p>UIX Designer and Developer</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <!-- single testimonial --> */}
        <div className="single-testimonial">
          <div className="row">
            <div className="col-xl-5">
              <div className="testimonial-img">
                <img
                  src="assets/img/testimonial/testimonial-3.png"
                  alt=""
                />
                <div className="quote">
                  <i className="lni lni-quotation"></i>
                </div>
              </div>
            </div>

            <div className="col-xl-6 offset-xl-1">
              <div className="content-wrapper">
                <div className="content">
                  <p>
                    Lorem ipsum dolor sit amet, consetetur sadipscing elitr,
                    sed dinonumy eirmod tempor invidunt ut labore et dolore
                    magna aliquyam erat, sed diam voluptua. At vero eos et
                    accusam et justo duo dolores et ea rebum. Stet clita
                    kasd gubergren, no sea takimata sanctus est Lorem.
                  </p>
                </div>
                <div className="info">
                  <h4>Michel Smith</h4>
                  <p>Traveler and Vloger</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section></>
