import OpenReplay from '@openreplay/tracker'

const tracker = new OpenReplay({
    projectKey: 'xlxG3pclLNEEftlSpwjn',
    ingestPoint: 'https://openreplay.querycharts.com/ingest',
    defaultInputMode: 0,
    obscureTextEmails: false,
    // __DISABLE_SECURE_MODE: true,
    obscureInputEmails: false
})

export default tracker