import React from 'react';

import Landing from './components/Landing';

 

const App = () => {
  return (
 
      <Landing />
 
  );
};

export default App;
